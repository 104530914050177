import { useEffect, useRef } from "react";

/**
 * Simple hook for tracking props from previous renders.
 */
export default function usePrevProps<T extends NonNullable<unknown>>(props: T) {
  const prevProps = useRef<T>({} as T);

  useEffect(() => {
    prevProps.current = props;
  });

  return prevProps;
}
